<template>
  <div
    class="row justify-center q-gutter-md items-start"
    :class="isSmallScreen ? 'q-pa-xs' : 'q-pa-xl'"
  >
    <q-card
      flat
      bordered
      style="width: 300px;"
      v-for="(val, i) in filteredMenus"
      :key="i"
    >
      <q-img style="height: 170px;" :src="val.img">
        <div class="absolute-bottom text-subtitle2 text-capitalize">
          {{ val.title }}
        </div>
      </q-img>
      <q-separator></q-separator>
      <q-list separator style="width:100%;">
        <q-item
          v-for="(val2, j) in val.links"
          :key="j"
          clickable
          v-ripple
          :to="val2.to"
          v-can="val2.isCan"
        >
          <q-item-section avatar top>
            <q-icon :name="val2.icon" color="blue-4"></q-icon>
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ val2.text }}</q-item-label>
            <q-item-label caption>{{ val2.caption }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  computed: {
    isSmallScreen() {
      return this.$q.screen.lt.md;
    },
    filteredMenus() {
      if (this.isSmallScreen) {
        let temp = JSON.parse(JSON.stringify(this.menus));
        for (let item of temp) {
          item.links = item.links.filter((menu) => menu.small_screen);
        }
        return temp;
      } else return this.menus;
    },
  },

  data() {
    return {
      jenjang: "",
      alert: false,
      menus: [
        {
          title: "Penilaian",
          links: [
            {
              to: "/penilaianabsensi",
              small_screen: true,
              text: "Absensi Siswa",
              icon: "checklist",
              caption: "Mengisi data kehadiran Siswa",
              isCan: ["MAPEL"],
            },
            {
              to: "/penilaianriwayatabsensi",
              small_screen: true,
              text: "Riwayat Absensi",
              icon: "switch_account",
              caption: "lihat dan edit data kehadiran",
              isCan: ["WALAS", "MAPEL", "KURIKULUM"],
            },
            {
              to: "/penilaianinputnilaimobile",
              small_screen: true,
              text: "Input Nilai Mata Pelajaran Mobile",
              icon: "edit",
              caption: "Mengisi penilaian Mata Pelajaran",
              isCan: ["MAPEL"],
            },
            {
              to: "/penilaianlistprestasi/-1/-1",
              small_screen: true,
              text: "Input Prestasi",
              icon: "emoji_events",
              caption: "Mengisi Catatan Prestasi Siswa",
              isCan: ["WALAS", "KEPSEK"],
            },
            {
              to: "/penilaianinputcatatan",
              small_screen: false,
              text: "Input Catatan Wali Kelas",
              icon: "playlist_add",
              caption: "Mengisi Catatan Wali Kelas Siswa",
              isCan: ["WALAS"],
            },
            {
              to: "/penilaianinputekskul",
              small_screen: true,
              text: "Input Nilai Ekstrakulikuler",
              icon: "theater_comedy",
              caption: "Mengisi Nilai Ekstrakulikuler Siswa",
              isCan: ["EKSKUL", "WALAS"],
            },
            {
              to: "/penilaianinputpembinaan/" + -1,
              small_screen: true,
              text: "Input Pembinaan",
              icon: "escalator_warning",
              caption: "Mengisi Data Pembinaan Siswa",
              isCan: ["BK"],
            },
          ],
          img: "/img/home_1.jpg",
        },
        {
          title: "Hasil Penilaian",
          links: [
            {
              to: "/hasilevaluasikehadiran",
              small_screen: true,
              text: "Evaluasi Kehadiran",
              icon: "directions_walk",
              caption: "Melihat Rekapitulasi Kehadiran Peserta Didik",
              isCan: ["WALAS", "KEPSEK", "MAPEL", "BK"],
            },
            {
              to: "/hasilranking",
              small_screen: false,
              text: "Ranking",
              icon: "star",
              caption: "Melihat Daftar Urutan Ranking Peserta Didik",
              isCan: ["WALAS", "KEPSEK"],
            },
            {
              to: "/hasilrekapnilai",
              small_screen: false,
              text: "Rekap Nilai",
              icon: "how_to_reg",
              caption: "Melihat Rekapitulasi Penilaian Peserta Didik",
              isCan: ["MAPEL", "WALAS", "KEPSEK"],
            },
            {
              to: "/hasilrekappembinaan/" + -1,
              text: "Riwayat Pembinaan",
              icon: "hail",
              caption: "Melihat Rekapitulasi Pembinaan Siswa",
              isCan: ["WALAS", "BK", "KEPSEK"],
            },
            {
              to: "/hasilraportdigital",
              small_screen: false,
              text: "Raport Digital",
              icon: "menu_book",
              caption: "Melihat Raport Peserta Didik",
              isCan: ["WALAS", "KEPSEK"],
            },
            {
              to: "/hasilraportmid",
              small_screen: false,
              text: "Raport Mid Semester",
              icon: "menu_book",
              caption: "Melihat Raport Mid Peserta Didik",
              isCan: ["WALAS", "KEPSEK", "BK"],
            },
          ],
          img: "/img/home_2.jpg",
        },
        {
          title: "Pengaturan Mata Pelajaran dan Rombongan Belajar",
          links: [
            {
              to: "/pengaturanmapel",
              small_screen: false,
              text: "Pengaturan Mata Pelajaran",
              icon: "book",
              caption: "Melihat Daftar dan Menambahkan Mata Pelajaran",
              isCan: ["KURIKULUM", "KEPSEK"],
            },
            {
              to: "/pengaturankompetensimapel",
              small_screen: false,
              text: "Pengaturan Kompetensi Dasar Mapel",
              icon: "account_tree",
              caption:
                "Melihat Daftar dan Menambahkan Kompetensi Dasar, Ulangan Harian dan Tugas pada Mata Pelajaran",
              isCan: ["KURIKULUM", "KEPSEK", "MAPEL", "WALAS", "EKSKUL"],
            },
            {
              to: "/pengaturangurumapel",
              small_screen: false,
              text: "Pengaturan Guru Mata Pelajaran",
              icon: "connect_without_contact",
              caption:
                "Menhubungkan Guru Mata pelajaran dengan dengan rombongan belajar",
              isCan: ["KURIKULUM", "MAPEL", "KEPSEK", "WALAS", "EKSKUL"],
            },
            {
              to: "/pengaturangurumapelbaru",
              small_screen: false,
              text: "Pengaturan Guru Mata Pelajaran Baru",
              icon: "connect_without_contact",
              caption:
                "Menhubungkan Guru Mata pelajaran dengan dengan rombongan belajar",
              isCan: ["KURIKULUM", "MAPEL", "KEPSEK", "WALAS", "EKSKUL"],
            },
            {
              to: "/pengaturanekskul",
              small_screen: false,
              text: "Pengaturan Ekstrakulikuler",
              icon: "celebration",
              caption:
                "Manajemen Ekstrakulikuler (Menambah, Mengubah dan Mengisi)",
              isCan: ["EKSKUL", "WALAS", "KURIKULUM", "KEPSEK"],
            },
            {
              to: "/pengaturanrombel",
              small_screen: false,
              text: "Pengaturan Rombongan Belajar",
              icon: "groups",
              caption: "Mengubah Status Peserta Didik",
              isCan: ["WALAS"],
            },
          ],
          img: "/img/home_3.jpg",
        },
      ],
      tahun_ajaran: {},
      user: {},
    };
  },
  async created() {
    this.user = await this.getCurrentUser();
    this.jenjang = localStorage.getItem("jenjang");
  },
  methods: {
    ...mapMutations(["clearState"]),
    ...mapGetters(["getCurrentUser"]),
    logout() {
      localStorage.clear();
      this.$q.notify({
        type: "positive",
        message: "proses logout berhasil",
      });
      this.clearState();
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="scss" scoped></style>
